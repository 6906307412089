export const showMainContent = () => ({
  type: 'SHOW_MAIN_CONTENT'
})

export const showControlBar = () => ({
  type: 'SHOW_CONTROL_BAR'
})

export const showEndSection = () => ({
  type: 'SHOW_END_SECTION'
})
